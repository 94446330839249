async function safeErrorJson(response) {
    const text = await response.text().catch(() => {
        return "Failed to read response body";
    });
    try {
        return JSON.parse(text);
    }
    catch {
        return {
            code: "invalid-response-body",
            message: `body: ${text.slice(0, 500)}`,
        };
    }
}
/**
 * @public
 */
export function createFindkitFetcher(init) {
    let currentJwtTokenPromise = null;
    /**
     * For internal tests only
     */
    function clear() {
        currentJwtTokenPromise = null;
    }
    const searchEndpointString = inferSearchEndpoint(init);
    const refresh = () => {
        const getJwtTokenArg = {
            searchEndpoint: searchEndpointString,
        };
        if (typeof (init === null || init === void 0 ? void 0 : init.getJwtToken) === "function") {
            currentJwtTokenPromise = init.getJwtToken(getJwtTokenArg);
        }
        else if (typeof FINDKIT_GET_JWT_TOKEN === "function") {
            currentJwtTokenPromise = FINDKIT_GET_JWT_TOKEN(getJwtTokenArg);
        }
    };
    const findkitFetch = async (options) => {
        var _a;
        if (!options.groups) {
            options = {
                ...options,
                groups: [
                    {
                        tagQuery: [],
                    },
                ],
            };
        }
        const started = Date.now();
        if (!currentJwtTokenPromise) {
            refresh();
        }
        const token = await currentJwtTokenPromise;
        const headers = {
            // This looks wrong but is intentional. We want to make "Simple CORS
            // requests" eg. requests without the OPTIONS preflight and
            // application/json is not allowed for those. So we just have to use
            // one of the allowed ones.
            // See https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests
            "Content-Type": "text/plain",
        };
        const fetchOptions = {
            method: "POST",
            signal: (_a = options.signal) !== null && _a !== void 0 ? _a : null,
            mode: "cors",
            credentials: "omit",
            headers,
            body: JSON.stringify({
                q: options.terms,
                groups: options.groups,
            }),
        };
        const endpoint = new URL(searchEndpointString);
        if (token) {
            if (typeof token.jwt !== "string") {
                throw new Error("[findkit] Expected GetJwtToken response to contain a 'jwt' property");
            }
            endpoint.searchParams.set("p", `jwt:${token.jwt}`);
        }
        const res = await fetch(endpoint.toString(), fetchOptions);
        if (res.status === 403) {
            const error = await safeErrorJson(res);
            if (error.code === "jwt-expired") {
                refresh();
                return findkitFetch(options);
            }
            throw new Error("[findkit] Permission denied3: " + (error.message || error.error));
        }
        if (!res.ok) {
            const error = await safeErrorJson(res);
            throw new Error(`[findkit] Bad response ${res.status} from search: ${error.message || error.error}`);
        }
        const responses = await res.json();
        return responses;
    };
    return {
        fetch: findkitFetch,
        clear,
        refresh,
    };
}
export function inferSearchEndpoint(options) {
    if (options.searchEndpoint) {
        return options.searchEndpoint;
    }
    else if (options.publicToken) {
        return createSearchEndpoint(options.publicToken);
    }
    else {
        throw new Error(`[findkit] publicToken or searchEndpoint is required`);
    }
}
/**
 * @public
 */
export function createSearchEndpoint(publicToken) {
    let version = "c";
    let subdomain = "search";
    let region = publicToken.split(":", 2)[1];
    // search.findkit.com is the eu-north-1 so must not set it explicitly
    if (region && region !== "eu-north-1") {
        subdomain = `search-${region}`;
    }
    try {
        const usp = new URLSearchParams(location.hash.slice(1));
        version = usp.get("__findkit_version") || version;
        subdomain = usp.get("__findkit_subdomain") || subdomain;
    }
    catch {
        // May crash for various reasons, no location object on a server, invalid
        // query string etc. We really don't care why it fails, since this is for
        // internal use only.
    }
    // prettier-ignore
    return `https://${subdomain}.findkit.com/${version}/${publicToken}/search?p=${publicToken}`;
}
